import Home from "./pages/Home";
import { PopupContextProvider } from "./providers/PopupContextProvider";
import { BrowserView, MobileView, isBrowser, isMobile, isAndroid } from 'react-device-detect';
import HomeMobile from "./pages/HomeMobile";
import logo from "./assets/images/ftc-logo.png";
import {useEffect, useState} from "react";

function App() {
	// get query params	
	const urlParams = new URLSearchParams(window.location.search);
	const forceDesktop = urlParams.get('force-desktop');
	const [id, setId] = useState("");

	useEffect(() => {
		let id = window.location.hash.substring(1);
		console.log("id", id);
		setId(id);
	}, []);

	return (
		<PopupContextProvider>
			<div className={`text-[#fcfffe] bg-mint-gradient min-h-screen text-center`}>
				<div className="flex flex-col text-center font-medium items-center mx-auto h-full max-w-176 py-8 px-4 sm:pt-24 sm:px-12 ">
					<img src={logo} alt="artwork" width="250" height="250" className="rounded-xl" />
					<h1 className="font-bold text-3xl pt-2 mt-4">Funding the Commons 2024 Soulbound Token</h1>
					<div className="flex flex-col mt-8 text-md">
						<div>
							<span>This NFT signifies that you attended Funding the Commons, Berkeley 2024. </span>
						</div>
						<div className="mt-6">
							<span>It is a non-transferable ('soulbound') and will also serve as a Soulbound Token for the Community Graphs project* in order to establish personhood, and seed a new web-of-trust for Funding the Commons. </span>
						</div>
					</div>
				</div>
				{(!isMobile || forceDesktop === 'true') ? <Home id={id} isAndroid={isAndroid}/> : <HomeMobile id={id}/>}
				<a href="https://www.communitygraphs.xyz" className="block mt-12 text-sm underline hover:text-black">*Learn more about Community Graphs</a>
			</div>
		</PopupContextProvider>
	);
}

export default App;
