import { useContext, useEffect, useState } from "react";
import MintButton from './ui/MintButton';
import { PopupContext } from "../providers/PopupContextProvider";

let txHash = "";
const apiEndpoint = 'https://ddy7ve7n1f.execute-api.us-west-2.amazonaws.com/default/mintNFT';

const MintNFT = ({ enabled, onMint, signedMessage, originalMessage, id }) => {
	const [isMinted, setIsMinted] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const popupContext = useContext(PopupContext)


	const mintHandler = async () => {
		if (!enabled) return;
		setLoading(true);
		const mintResponse = await fetch(apiEndpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({signedMessage, originalMessage, id})
		});
		const mintResponseJson = await mintResponse.json();
		txHash = mintResponseJson.transaction?.hash;
		if(mintResponse.status === 200) {
			console.log("mintResponseJson",mintResponseJson);
			setIsMinted(true);
		} else {
			popupContext.showPopup({
				text: mintResponseJson.error,
				buttonText: "Okay",
				status: "error",
				onClose: () => popupContext.hidePopup()
			});
		}
		setLoading(false);
	}

	useEffect(() => {
		if (isMinted) {
			onMint(txHash);
		}
	}, [isMinted]);

	return (
		<MintButton
			text="Step 3: Mint NFT"
			loading={isLoading}
			checked={isMinted}
			enabled={enabled && !isLoading}
			onClick={mintHandler}
		/>
	);
}

export default MintNFT;

