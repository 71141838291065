import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { ReactNode } from "react";

const projectId = '3a1960967ff2daef128a63ec36163de3'

// 2. Set chains
const optimism = {
  chainId: 10, // Correct chain ID for Optimism
  name: 'Optimism', // Name of the chain
  currency: 'ETH', // Currency used
  explorerUrl: 'https://optimistic.etherscan.io', // Optimism-specific explorer URL
  rpcUrl: 'https://mainnet.optimism.io' // Official Optimism RPC URL
}
const mainnet = {
  chainId: 1, // Correct chain ID for Mainnet
  name: 'Mainnet', // Name of the chain
  currency: 'ETH', // Currency used
  explorerUrl: 'https://etherscan.io', // Mainnet-specific explorer URL
  rpcUrl: 'https://mainnet.infura.io/v3/' + projectId // Official Mainnet RPC URL
}

// 3. Create modal
const metadata = {
  name: 'Community Graphs SBT - Funding the Commons 2024',
  description: 'Community Graphs SBT - Funding the Commons 2024',
  url: 'https://ftc.communitygraphs.xyz', // origin must match your domain & subdomain
  icons: [''],
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [optimism],
  projectId,
  includeWalletIds: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
  allWallets: 'HIDE',
  enableAnalytics: true // Optional - defaults to your Cloud configuration
});

export function Web3ModalProvider({ children }) {
  return children
}

