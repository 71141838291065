import { useCallback, useEffect, useContext, useState } from "react";
import ConnectButton from "../components/ConnectWallet";
import MintNFT from "../components/MintNFT";
import { PopupContext } from "../providers/PopupContextProvider";
import { Web3ModalProvider } from "../providers/Web3Modal";
import { useDisconnect } from "@web3modal/ethers/react";


//passed one string parameter id
export default function Home({id, isAndroid}) {
	const [userData, setUserData] = useState(undefined);
	const [step, setStep] = useState(1);
	const [txHash, setTxHash] = useState(undefined);
	const [originalMessage, setOriginalMessage] = useState("");
	const [signedMessage, setSignedMessage] = useState("")
	const popupContext = useContext(PopupContext);
	const [userAddress, setUserAddress] = useState(undefined);
	const ticketFailedMessage = 'Failed to verify your scanned ticket to Edge City. Please make sure that you have a valid ticket, and that it was scanned.';
	const successfulMintMessage = 'Your NFT has been minted successfully!';
	const { disconnect } = useDisconnect();

	const onConnectionStatusChange = useCallback(connected => {
		setStep(connected ? 2 : 1);
	}, []);

	const setUserAddressCallback = useCallback(address => {
		setUserAddress(address);
	}, []);

	const onMint = useCallback(txHash => {
		setStep(3);
		setTxHash(txHash);
		popupContext.showPopup({
			text: successfulMintMessage,
			buttonText: "Okay",
			status: "success",
			onClose: () => popupContext.hidePopup()
		});
	}, [popupContext]);

	//do on start



	return (
		<Web3ModalProvider>
			<div className="flex flex-col gap-4 text-center items-stretch w-72 mx-auto">
				<ConnectButton enabled={step > 0}
				   onConnectionStatusChange={onConnectionStatusChange}
				   setUserAddressCb={setUserAddressCallback}
				   setOriginalMessage={setOriginalMessage}
				   setSignedMessage={setSignedMessage}
				/>
				<MintNFT enabled={step > 1} onMint={onMint} signedMessage={signedMessage} originalMessage={originalMessage} id={id}/>
				{txHash && (
					<div className="flex flex-col gap-2">
						<span className="text-sm">Transaction:</span>
						<a href={`https://optimistic.etherscan.io/tx/${txHash}`} target="_blank"
							rel="noreferrer" className="text-sm underline hover:text-black">View on Etherscan</a>
					</div>
				)}
				{isAndroid && (
					<div className="flex flex-col gap-2">
						<span className="text-sm">Please close Metamask app after minting</span>
					</div>
				)}
			</div>
		</Web3ModalProvider>
	);
}

