import { useEffect, useState } from 'react';
import { BrowserProvider, ethers } from "ethers";
import MintButton from './ui/MintButton';
import { useWeb3Modal, useWeb3ModalEvents, useWeb3ModalState } from "@web3modal/ethers/react";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { v4 as uuidv4 } from 'uuid';

const ConnectButton = ({ enabled, onConnectionStatusChange, setUserAddressCb, setOriginalMessage, setSignedMessage }: { enabled: boolean, onConnectionStatusChange: (connected: boolean) => void, setUserAddressCb: (addr: string) => void }) => {
	const { open } = useWeb3Modal();
	const { open: opened } = useWeb3ModalState()
	const { walletProvider } = useWeb3ModalProvider();
	const events = useWeb3ModalEvents()
	const { address, isConnected } = useWeb3ModalAccount()

	const [isConnectedState, setIsConnectedState] = useState(false);
	const [isSigned, setIsSigned] = useState(false);

	useEffect(() => {
		if (events.data.event === "CONNECT_SUCCESS") {
			setIsConnectedState(true);
			setUserAddressCb(address);
			onConnectionStatusChange(true);
		} else {
			setIsConnectedState(false);
			onConnectionStatusChange(false);
		}
	}, [events]);

	useEffect(() => {
		if (isConnected) {
			setIsConnectedState(true);
			setUserAddressCb(address);
			onConnectionStatusChange(true);
		} else {
			setIsConnectedState(false);
			onConnectionStatusChange(false);
		}
	}, [opened, isConnected]);

	const sign = async () => {
		if (!enabled) return;
		if (!isConnected || !walletProvider) throw Error('User disconnected');

		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const nonce = uuidv4();
			const originalMessage = `I am signing this message to authenticate my wallet address. Unique ID: ${nonce}.`;
			setOriginalMessage(originalMessage);
			const signedMessage = await signMessage(signer, originalMessage);
			setSignedMessage(signedMessage);
			setIsSigned(true);
		}
		catch (err) {
			console.error("error signing message", err);
		}
	}

	const connect = async () => {
		if (!enabled) return;
		try {
			if (!isConnected) {
				await open();
			} else {
				setIsConnectedState(true);
				setUserAddressCb(address);
			}
			return;
		} catch (error) {
			console.error(error);
		}
	}

	const signMessage = async (signer, message) => {

		try {
			return signer.signMessage(message);
		} catch (error) {
			console.error(error);
		}
		return false;
	}

return (
	<div>
		<MintButton
			text={"Step 1: Connect Wallet"}
			loading={false}
			enabled={enabled}
			checked={isConnectedState && enabled}
			onClick={connect}
		/>
		<span className="text-xs">{address ? (`Connected wallet: ${address}`) : ''}</span>
		<div style={{ height: '18px' }}></div>
		<MintButton
			text="Step 2: Sign Message"
			loading={false}
			enabled={enabled && isConnectedState}
			checked={isSigned}
			onClick={sign}
		/>
	</div>
	);
};

export default ConnectButton;

