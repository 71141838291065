import React, { useCallback, useState } from 'react';
import closeIcon from '../assets/icons/close.svg';


const initialPopupData = {
	text: "",
	buttonText: "Okay",
	status: "error",
	onClose: () => { },
};

const initialContextValue = {
  showPopup: () => {},
  hidePopup: () => {},
};

export const PopupContext = React.createContext(initialContextValue);

export const PopupContextProvider = ({ children  }) => {
	const [show, setShow] = useState(false);
	const [popupData, setPopupData] = useState(initialPopupData);

	const showPopup = useCallback(data => {
		setPopupData(data);
		setShow(true);
	}, [])

	const hidePopup = useCallback(() => {
		setPopupData(initialPopupData);
		setShow(false);
	}, []);

	return (
		<PopupContext.Provider value={{showPopup, hidePopup}}>
			{show &&
				<div className='fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center'>
					<div className={`relative flex flex-col gap-4 bg-dark-blue text-white text-xl px-10 py-12 rounded-3xl w-90 ${popupData.status === 'success' ? 'bg-purple' : 'bg-dark-blue'}`}>
						<img src={closeIcon} alt='close' width='24' height='24' className='absolute top-4 right-4 cursor-pointer' onClick={popupData.onClose} />
						<p className='text-center'>{popupData.text}</p>
						<button className='bg-white text-black rounded-full py-2' onClick={popupData.onClose}>{popupData.buttonText}</button>
					</div>
				</div>
			}
			{children}
		</PopupContext.Provider>
	);
};

